.footer{
    background-color: #023047;
    color: white;
    text-align: center;
    padding: 50px 180px 10px 180px;
}

.footer-top{
    display: flex;
    margin-bottom: 50px;
}

.FootCont{
    border-right: 2px solid white;
    padding: 10px 50px 20px 50px;
}
.foot-about-main{
    padding: 10px 50px 20px 50px;
    border-right: 2px solid white;
}
.foot-about{
    display: flex;
}
.foot-about p{
    width: 400px;
}
.foot-follow{
    padding: 10px 50px 20px 50px;
    border: 30px;
}
.foot-follow h3, .foot-about-main h3, .FootCont h3{
    margin-bottom: 20px;
}
.foot-about img{
    border-radius: 10px;
    padding-top: 20px ;
}
.foot-follow a img{
    margin-right: 13px;
    border-radius: 10%;
    box-shadow: 2px 2px 3px black;
}
.phone-email-logo{
    display: flex;
    padding: 5px 0px;
}
.phone-email-logo img{
    margin-right: 15px;
}