.signin form{
    background: linear-gradient(#023047,white);
    display: flex;
    flex-direction: column;
    padding: 235px 531px ;
}
.signin form input{
    margin: 15px;
    font-size: 20px;
    width: 400px;
    padding: 7px 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 3px black;
}
.signin form button{
    margin: 15px auto;
    font-size: 20px;
    width: 120px;
    padding: 7px 20px;
    border-radius: 10px;
    background: linear-gradient(to right,#5AC5DE, #053440);
    cursor: pointer;
    color: white;
}
.signin form button:hover{
    font-weight: bold;
}