#search{
    margin: -27px auto -25px auto ;
    padding: 10px 30px ;
    font-size: 25px;
    display: block;
    width: 900px;
    border-radius: 10px;
    position: relative;
    box-shadow: 5px 5px 10px black;
}
