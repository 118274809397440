.community-heading{
    display: flex;
    margin: 0px 20px;
    padding-top: 7px;
}
.community-heading div h3{
    padding-left: 10px ;
}
.profile{
    text-align: center;
    width: 300px;
    padding: 25px ;
    background-color:#023047 ;
    color: #f5deb3;
    margin: 15px 20px;
    border-radius: 20px;
}
.profile-stats{
    display: flex;
    justify-content: space-evenly;
    margin-top: 17px;
}
.stats{
    font-size: 20px;
}
.community-features{
    padding-bottom: 1px;
}
.community-features div{
    background-color:#023047 ;
    width: 300px;
    margin: 10px 20px;
    text-align: center;
    padding: 10px 37px 10px 10px;
    border-radius: 10px;
}
.community-features div:hover{
    cursor: pointer;
}
.community-options{
    display: flex;
    color: wheat;
}
.community-options img{
    color: inherit;
    filter: invert(86%) sepia(44%) saturate(277%) hue-rotate(334deg) brightness(101%) contrast(92%);
}
.community-options h4{
    padding: 6px 15px;
    color: wheat;
}
#back-button{
    padding: 5px 5px 0px 0px;
}
