.network{
    display: flex;
    justify-content: space-around;
    padding: 50px 100px;
    background: #8cc2da;
}
.net-name{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    color: #023047;
}
#net-img{
    height: 370px;
    border-radius: 15px;
    box-shadow: 5px 5px 10px black;
    margin-left: 100px ;
    margin-right: 100px;
}
.net-g{
    font-size: 70px;
    margin: 0px auto 15px auto ;
    width: 700px;
}
.net-p{
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    width: 500px;
}
.net-b{
    padding: 10px 30px;
    border-radius: 20px;
    background-color: #023047;
    color: white;
    font-size: 18px;
    height: 50px;
    box-shadow: 2px 2px 3px black;
    border: 0PX;
}
.net-b{
    cursor: pointer;
}