*{
    margin: 0px;
    padding: 0px;
}
body{
    box-sizing: border-box;
   

}
.header{
    background-image: url("/src/images/bg-3.png");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 160px;
}
#logo{
    height: 45px;
    margin: 8px 10px;
}
.navbar{
    display: flex;
    background-color: #023047;
    /* opacity: 50%; */
}
.icon-name{
    display: flex;
}
#nav-name{
    margin: 17px 5px;
    color: orange;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px ;
    font-weight: bold;
    text-shadow: 5px 5px 5px black;
}
.nav-menu{
    margin: 0px 260px;
}
.nav-menu ul{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;

}
.nav-menu ul li{
    margin: 0px 14px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    /* text-shadow: 5px 5px 5px ; */
}
.nav-menu ul li:hover{
    cursor: pointer;
    color: #fb8500;
}
.nav-buttons{
    margin: 13px 10px;
}
.nav-buttons button{
    margin: 0px 7px;
    padding: 5px 15px;
    border-radius: 15px;
    color: white;
    font-size: 20px;
    background-color: #8ecae6;
    width: 120px;
    border: 0px;
    box-shadow: 2px 2px 2px black;
}
.nav-buttons button:hover{
    cursor: pointer;
}
.nav-below{
    color: #FFB703;
    text-align: center;
    margin: 30px auto 20px auto;
    font-size: 40px;
    text-shadow: 3px 3px 5px black;
}
.nav-below-2{
    color: white;
    text-align: center;
    font-size: 23px;
    width: 700px;
    margin: auto;
    text-shadow: 5px 5px 5px black;
}
#sign-up{
    background-color: #219ebc;
}