.services-main{
    background-color:#8cc2da ;
    margin-top: -20px;
}
.cards{
    width: 300px;
    height: 365px;
    background: linear-gradient( #dae4e9,#4F7080);
    margin: 30px;
    border-radius: 20px;
    padding: 10px ;
}
.card-h1{
    margin: 0px 0px 10px 0px;
    color:#023047 ;
}
.card-img{
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 5px black;
}
.card-btn{
    bottom: 0;
    border: 0px;
    font-size: 20px;
    background-color: inherit;
    cursor: pointer;
}
.card-btn:hover{
    font-weight: bold;
    /* background: linear-gradient(to right, #316A78, gray); */
    padding: 3px 85px;

}
.card-desc {
    height: 47%;
    color:#023047 ;
    width: 80%;
    margin-left:auto ;
    margin-right: auto;
}
.services-data{
    display: flex;
    text-align: center;
}
#service-name{
    text-align: center;
    font-size: 60px;
    margin-top: 20px;
    color:#023047 ;
}